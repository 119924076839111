import './AnswerGeneration.css';
import GPTAnswer from '../../components/GPTAnswer/GPTAnswer';

function AnswerGeneration() {
    return (
        <div className='AnswerGenerationContainer' style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/blueBackground.png)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className='AnswerGenerationTextContainer'>
                <p className='AnswerGenerationIntroduction'>Kraftfullt AI funktionalitet</p>
                <GPTAnswer answer='Upp till 70% av frågorna i nya upphandlingar har besvarats tidigare. Låt Hedda skriva svaren åt er.' />
                <p className='AnswerGenerationIntroduction'>Spara tid och lämna in fler anbud med högre kvalitet.</p>
{/*                 <p className='AnswerGenerationHeader'>Up to 70% of procurement questions have been answered in previous tenders. Let Hedda write them for you.</p>
 */}            </div>
        </div>
    );
}

export default AnswerGeneration;
