import './HeroSection.css';
import VideoBackground from '../../components/VideoBackground/VideoBackground';
import TextRoulette from '../../components/TextRoulette/TextRoulette';
import ShortcutButton from '../../components/ShortcutButton/ShortcutButton';
import ModalComponent from '../../components/ModalComponent/ModalComponent';
import { useDisclosure } from '@mantine/hooks';

function HeroSection() {
    const [opened, { open, close }] = useDisclosure(false);

    return (
        <div className="HeroSectionContainer">
            <ModalComponent opened={opened} close={close} />
            <div className="HeroSectionVideoContainer">
                <VideoBackground />
            </div>
            <div className="HeroSectionContent">
                <div className="HeroSectionLogoContainer">
                    Hedda
                </div>
                <div className="HeroSectionTextButtonContainer">
                    <div className="HeroSectionTextContainer">
                        <p className="HeroSectionTextTitle">Vi gör anbudsprocessen</p>
                        <TextRoulette texts={["Snabb", "Enkel", "Säker", "Effektiv"]} />
                    </div>
                    <div className="HeroSectionButtonContainer">
                        <ShortcutButton text="Boka demo" onClick={open} />
                    </div>
                </div>

            </div>

        </div>
    );
}

export default HeroSection;