import './Startpage.css';
import HeroSection from '../../segments/HeroSection/HeroSection';
import BidPlatform from '../../segments/BidPlatform/BidPlatform';
import KeyFeatures from '../../segments/KeyFeatures/KeyFeatures';
import AnswerGeneration from '../../segments/AnswerGeneration/AnswerGeneration';
import SearchDemo from '../../segments/SearchDemo/SearchDemo';
import Questions from '../../segments/Questions/Questions';
import Footer from '../../segments/Footer/Footer';

function Startpage() {
    return (
        <div className="StartpageBody">
            <HeroSection />
            <BidPlatform />
            <KeyFeatures />
            <AnswerGeneration />
            <SearchDemo />
            <Questions />
            <Footer />
        </div>
    );
}

export default Startpage;