import './KeyFeatures.css';
import FeatureShowcase from '../../components/FeatureShowcase/FeatureShowcase';
import { IconSparkles, IconUsers, IconCheck, IconBadgeFilled, IconLockFilled } from '@tabler/icons-react';


function KeyFeatures() {
    return (
        <div className='KeyFeaturesSegmentContainer'>
            <p className='KeyFeaturesIntroductionText'>Ett kraftfullt skrivverktyg</p>
            <p className='KeyFeaturesHeaderText'><span style={{color: '#2662BA'}}>Superchargea</span>  anbudsskrivandet med nyckelfunktionalitet</p>
            <div className='KeyFeaturesContainer'>
            <FeatureShowcase 
  icon={<IconSparkles size={24} color='black'/>} 
  title="Generera svar" 
  text="Upp till 70% av upphandlingsfrågorna har blivit besvarade i tidigare anbud. Vi genererar svar automatisk, som sparar tid och låter er fokusera på att höja kvaliten i anbudet."
/>

<FeatureShowcase 
  icon={<IconUsers size={24} color='black'/>} 
  title="Effektivt samarbete" 
  text="Tilldela personer frågor, lämna feedback, och överblicka framsteg. Sammarbete har aldrig varit såhär enkelt."
/>

<FeatureShowcase 
  icon={<IconCheck size={24} color='black'/>} 
  title="Kvalitetssäkring" 
  text="Undvik onödiga missar och avdrag. Vårt system kontrollerar svar med hänsyn till frågor och krav."
/>

<FeatureShowcase 
  icon={<IconBadgeFilled size={24} color='black'/>} 
  title="Full Kontroll" 
  text="Utnyttja Hedda för att hantera skrivprocessen. Spara tid, sammarbeta och öka kvaliten. Vi låter er fokusera på det som är det viktiga."
/>

<FeatureShowcase 
  icon={<IconLockFilled size={24} color='black'/>} 
  title="Ert data är säkert" 
  text="Er data hanteras säkert i EU och ingen träning sker på er data. Vi använder Azure och utför regelbundna backups för er säkerhet."
/>

            </div>
        </div>
    )

}

export default KeyFeatures;