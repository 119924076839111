import './BidPlatform.css';

function BidPlatform() {
    return (
        <div className='BidPlatformContainer '>
            <div className='BidPlatformSubContainerLeft'>
                <div className='BidPlatformTextContainer'>
                    <p className='BidPlatformIntroductionText'>Plattform</p>
                    <p className='BidPlatformHeaderText'>Ert <span style={{color: '#2662BA'}}>nya</span> anbudsverktyg.</p>
                    <p className='BidPlatformDescriptionText'>Enkelt överblicka era anbud och hantera arbetsuppgigfter. Se hur många frågor som är besvarade, behöver granskas och vilka som behöver påbörjas. Sammarbeta smidigt med era arbetsuppgifter med bland annat kommentarer och effiktivesera flödet med AI-drivna verktyg. </p>
                </div>
            </div>
            <div className='BidPlatformSubContainerRight'>
                <img src={`${process.env.PUBLIC_URL}/bidWorkspace.png`} alt='placeholder' className='BidPlatformImage' />
            </div>

        </div>
    )
}


export default BidPlatform;