import './TextRoulette.css';
import React from 'react';
import { useEffect, useRef } from 'react';

function TextRoulette(props) {
    const textRef1 = useRef();
    const textRef2 = useRef();
    const texts = props.texts;

    const startTextRoulette = () => {
        let i = 0;
        let j = (i + 1) % texts.length;
        textRef1.current.innerHTML = texts[i];
        textRef2.current.innerHTML = texts[j];
        setInterval(() => {
            textRef1.current.classList.add("TextRouletteFirstTextAnimation");
            textRef2.current.classList.add("TextRouletteSecondTextAnimation");
            textRef1.current.classList.add("TextRouletteFirstTextTransform");
            textRef2.current.classList.add("TextRouletteSecondTextTransform");

            setTimeout(() => {
                textRef1.current.classList.remove("TextRouletteFirstTextAnimation");
                textRef2.current.classList.remove("TextRouletteSecondTextAnimation");
                i = (i + 1) % texts.length;
                j = (i + 1) % texts.length;
                textRef1.current.innerHTML = texts[i];
                textRef2.current.innerHTML = texts[j];
                textRef1.current.classList.remove("TextRouletteFirstTextTransform");
                textRef2.current.classList.remove("TextRouletteSecondTextTransform");

            }, 700);
        }, 3000);
    }

    useEffect(() => {
        startTextRoulette();
    }
    , []);


  return (
    <div className="TextRouletteContainer">
      <p ref={textRef1} className="TextRouletteText TextRouletteFirstText TextRouletteFirstTextAnimation"></p>
      <p ref={textRef2} className="TextRouletteText TextRouletteSecondText TextRouletteSecondTextAnimation">sdfs</p>
    </div>
  );
}

export default TextRoulette;