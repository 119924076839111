import './SearchDemo.css';

function SearchDemo() {
    return (
        <div className="SearchDemoContainer">
            <p className='SearchDemoIntroduction'>AI-sökning</p>
            <p className='SearchDemoText'><span style={{ color: '#2662BA' }}>Sök</span> i gamla anbud och filer</p>
            <video autoPlay loop muted playsInline className="SmartSearchDemoVideo">
                <source src={`${process.env.PUBLIC_URL}/searchDemo.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    )
}

export default SearchDemo;