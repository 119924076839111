import './VideoBackground.css';
import React, { useEffect, useRef } from 'react';

function VideoBackground() {
  const videoRef = useRef(null);

  useEffect(() => {
    const playVideo = async () => {
      try {
        await videoRef.current.play();
      } catch (err) {
        console.log("Video kan inte spelas automatiskt:", err);
      }
    };

    playVideo();
  }, []);

  return (
    <div className="VideoBackgroundContainer">
      <video autoPlay loop muted playsInline className="VideoBackgroundVideo" ref={videoRef}>
        <source src={`${process.env.PUBLIC_URL}/video2.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default VideoBackground;


