import './Questions.css';

function Questions() {
    return (
        <div className='QuestionsContainer'>
            <div className='QuestionsSubContainerLeft'>
                <div className='QuestionsTextContainer'>
                    <p className='QuestionsIntroductionText'>Effektiv hantering</p>
                    <p className='QuestionsHeaderText'>Få <span style={{color: '#2662BA'}}>full</span> kontroll över anbudet.</p>
                    <p className='QuestionsDescriptionText'>Enkel hantering, spåra framsteg och en delad arbetsyta. Funktionalitet för att påminnelse: för att alltid vara i tid. Vi vill hjälpa er slippa huvudvärken med administering, skrivandet och anbuden.</p>
                </div>
            </div>
            <div className='QuestionsSubContainerRight'>
                <img src={`${process.env.PUBLIC_URL}/questions.png`} alt='placeholder' className='QuestionsImage' />
            </div>

        </div>
    )
}


export default Questions;