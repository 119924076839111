import './Footer.css';
import ShortcutButton from '../../components/ShortcutButton/ShortcutButton';
import { useDisclosure } from '@mantine/hooks';
import ModalComponent from '../../components/ModalComponent/ModalComponent';

function Footer() {
    const [opened, { open, close }] = useDisclosure(false);

    return (
        <div className='FooterRoot'>
            <div className='FooterContainer'>
                <ModalComponent opened={opened} close={close} />
                <div className='FooterSubContainer'>
                    <p className='FooterIntroduction'>Låt Hedda hjälpa</p>
                    <p className='FooterText'><span style={{ color: '#2662BA' }}>Superchargea</span> ert bid-team</p>
                
                </div>
                <div className='FooterSubContainer'>
                    <div className='FooterButtonContainer'>
                        <ShortcutButton text='Boka demo' color='white' onClick={open} />
                    </div>
                    <p className='FooterPromoText'>så visar vi hur enkelt anbudsskrivande kan vara</p>
                    <p className='FooterSecondaryText'>Hedda AB © 2024 All rights reserved.</p>
                    
                </div>
            </div>
            
            
        </div>
    )
}

export default Footer;